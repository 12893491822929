import React, { useState } from 'react';
import './App.css';
import HeadsOrTails from './components/HeadsOrTails';

function App() {
 

  return (
    <div id="background">
      <HeadsOrTails/>
    </div>
  );
}

export default App;
